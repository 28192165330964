import { template as template_b79c52ec42c747f58be81ef3417d6615 } from "@ember/template-compiler";
const WelcomeHeader = template_b79c52ec42c747f58be81ef3417d6615(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
