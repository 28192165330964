import { template as template_fb7ad5931f8e45c4aca82c491463936a } from "@ember/template-compiler";
const FKControlMenuContainer = template_fb7ad5931f8e45c4aca82c491463936a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
