import { template as template_5b324d63ae5f40ba8be00f888c3e9b1a } from "@ember/template-compiler";
const FKLabel = template_5b324d63ae5f40ba8be00f888c3e9b1a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
