import { template as template_45c9596e5a244f51939933d4b954c7c5 } from "@ember/template-compiler";
const EmptyState = template_45c9596e5a244f51939933d4b954c7c5(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
