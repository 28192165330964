import { template as template_13e88bb60244419ca8a04232be0bcc79 } from "@ember/template-compiler";
import { and, eq, notEq } from "truth-helpers";
import getURL from "discourse-common/lib/get-url";
const Logo = template_13e88bb60244419ca8a04232be0bcc79(`
  {{#if (and @darkUrl (notEq @url @darkUrl))}}
    <picture>
      <source srcset={{getURL @darkUrl}} media="(prefers-color-scheme: dark)" />
      <img
        id="site-logo"
        class={{@key}}
        src={{getURL @url}}
        width={{if (eq @key "logo-small") "36"}}
        alt={{@title}}
      />
    </picture>
  {{else}}
    <img
      id="site-logo"
      class={{@key}}
      src={{getURL @url}}
      width={{if (eq @key "logo-small") "36"}}
      alt={{@title}}
    />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Logo;
