import { template as template_e6f948f3c6c348709f49dcb85c67a6ac } from "@ember/template-compiler";
const SidebarSectionMessage = template_e6f948f3c6c348709f49dcb85c67a6ac(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
